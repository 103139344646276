import React from 'react'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import { StaticImage } from 'gatsby-plugin-image'

const ApproachPage = () => (
  <Layout>
    <Seo
      title="Ressourcenorientierung"
      description="Ressourcenorientierte Beratung schaut nicht nur auf Schwäschen und Defizite, sondern besonders auch auf vorhandene
        Stärken und konstruktive Kräfte."
    />
    <section className="flex flex-column flex-row-ns">
      <div className="w-100 w-50-ns">
        <StaticImage
          src={'../../images/baum.jpg'}
          alt="Baum"
          imgClassName="mb2 mr2"
        />
      </div>
      <div className="w-100 w-50-ns mt3 mt0-ns ml3-ns">
        <h1 id="ressourcenorientiert">Ressourcenorientierung</h1>
        <p>
          Bedeutsam ist die familientherapeutische Unterscheidung von Defizit-
          und Ressourcenorientierung. Wenn Sie sich bei mir zur Beratung
          anmelden, dann deshalb, weil Sie unter bestimmten Beeinträchtigungen,
          Symptomen und/oder Konfliktsituationen leiden.
        </p>
        <p>
          Einerseits ist es mir wichtig, von Ihnen erlebte Mängel und Defizite
          als Gegebenheit und in ihrem Entstehungszusammenhang wahrzunehmen,
          andererseits achte ich besonders auf die konstruktiven Kräfte, die
          immer auch am Werk sind, und bringe diese ins Gespräch ein.
        </p>
        <p>
          Theodor Adornos Sentenz „Es gibt kein richtiges Leben im falschen“ ist
          aus familientherapeutischer Perspektive zurückzuweisen. Es gilt das –
          wenn auch anfanghafte – Gelingen im schwierigen Leben hervorzuheben
          und auszubauen, mit der Chance, dass eines Tages die Waagschale kippt
          und aus dem schwierigen Leben ein gelingendes Leben wird.
        </p>
      </div>
    </section>
  </Layout>
)

export default ApproachPage
